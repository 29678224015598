import { createContext, useState, useContext, useCallback } from 'react'
import axios from '../api/axios'

const SingleProductContext = createContext()

export const useSingleProduct = () => useContext(SingleProductContext)

const fetchProductById = async (id) => {
  try {
    const { data } = await axios.get(`/products/${id}`)
    return data
  } catch (error) {
    throw error
  }
}

export const SingleProductProvider = ({ children }) => {
  const [singleProduct, setSingleProduct] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState(null)

  const getProduct = useCallback(async (id) => {
    setIsLoading(true)
    setIsError(false)
    try {
      const data = await fetchProductById(id)
      setSingleProduct(data)
    } catch (err) {
      setIsError(true)
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  return (
    <SingleProductContext.Provider
      value={{
        singleProduct,
        getProduct,
        isLoading,
        isError,
        error,
      }}
    >
      {children}
    </SingleProductContext.Provider>
  )
}
