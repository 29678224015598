import { createContext, useState, useContext, useEffect } from 'react'
import axios from '../api/axios'

const ProductContext = createContext()

export const useProducts = () => useContext(ProductContext)

const fetchProducts = async (page, limit, category, name, sortBy) => {
  const params = { page, limit, name, sortBy }
  if (category) {
    params.category = category
  }
  if (name) {
    params.name = name
  }

  try {
    const { data } = await axios.get('/products', { params })
    return data
  } catch (error) {
    throw error
  }
}

export const ProductProvider = ({ children }) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(12)
  const [category, setCategory] = useState('')
  const [name, setName] = useState('')
  const [sortBy, setSortBy] = useState('createdAt:desc') // Default sort
  const [products, setProducts] = useState([])
  const [totalDocs, setTotalDocs] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [hasPrevPage, setHasPrevPage] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [limitPerPage, setLimitPerPage] = useState(12)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true)
      setIsError(false)
      try {
        const data = await fetchProducts(page, limit, category, name, sortBy)
        setProducts(data.docs || [])
        setTotalDocs(data.totalDocs || 0)
        setTotalPages(data.totalPages || 1)
        setCurrentPage(data.page || 1)
        setHasPrevPage(data.hasPrevPage || false)
        setHasNextPage(data.hasNextPage || false)
        setLimitPerPage(data.limit || limit)
      } catch (err) {
        setIsError(true)
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }

    getProducts()
  }, [page, limit, category, name, sortBy]) // Trigger fetch when sortBy changes

  return (
    <ProductContext.Provider
      value={{
        products,
        totalDocs,
        totalPages,
        currentPage,
        hasPrevPage,
        hasNextPage,
        limitPerPage,
        page,
        limit,
        category,
        setCategory,
        setPage,
        setLimit,
        sortBy,
        setSortBy, // Expose setSortBy to update sorting logic
        isLoading,
        isError,
        error,
        name,
        setName,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
