import { createContext, useState, useEffect, useContext } from 'react'
import axios from '../api/axios'

const CategoriesContext = createContext()

export const useCategories = () => useContext(CategoriesContext)

export const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState(null)

  const fetchCategories = async () => {
    try {
      setIsLoading(true)
      setIsError(false)
      const { data } = await axios.get('/categories')
      setCategories(data)
    } catch (err) {
      setIsError(true)
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <CategoriesContext.Provider
      value={{
        categories,
        selectedCategory,
        setSelectedCategory,
        isLoading,
        isError,
        error,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  )
}
