import { createContext, useState, useContext, useEffect } from 'react'

const CartContext = createContext()

export const useCart = () => useContext(CartContext)

const saveCartToLocalStorage = (cart) => {
  localStorage.setItem('cart', JSON.stringify(cart))
}

const loadCartFromLocalStorage = () => {
  const storedCart = localStorage.getItem('cart')
  return storedCart ? JSON.parse(storedCart) : []
}

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(loadCartFromLocalStorage())

  useEffect(() => {
    saveCartToLocalStorage(cart)
  }, [cart])

  const addToCart = (product, selectedSize, selectedColor, quantity = 1) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (item) =>
          item.name === product.name &&
          item.selectedSize === selectedSize &&
          item.selectedColor === selectedColor
      )

      if (existingItemIndex > -1) {
        // If item exists, update its quantity
        const updatedCart = [...prevCart]
        updatedCart[existingItemIndex].quantity += quantity

        // Ensure quantity doesn't drop below 1
        if (updatedCart[existingItemIndex].quantity < 1) {
          updatedCart.splice(existingItemIndex, 1) // Remove item if quantity is less than 1
        }
        return updatedCart
      } else {
        // Add new item to cart
        return [
          ...prevCart,
          {
            name: product.name,
            selectedSize,
            selectedColor,
            price: product.isSale ? product.salePrice : product.price,
            imageUrl: product.images?.urls?.[0] || '',
            quantity: Math.max(1, quantity),
            _id: product._id,
          },
        ]
      }
    })
  }

  const updateQuantity = (
    productName,
    selectedSize,
    selectedColor,
    quantity
  ) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (item) =>
          item.name === productName &&
          item.selectedSize === selectedSize &&
          item.selectedColor === selectedColor
      )

      if (existingItemIndex > -1) {
        const updatedCart = [...prevCart]
        if (quantity > 0) {
          // Update the item's quantity
          updatedCart[existingItemIndex].quantity = quantity
        } else {
          // Remove the item if quantity is zero or less
          updatedCart.splice(existingItemIndex, 1)
        }
        return updatedCart
      }

      // If item is not found, return cart as is
      return prevCart
    })
  }

  const removeFromCart = (productName, selectedSize, selectedColor) => {
    setCart((prevCart) =>
      prevCart.filter(
        (item) =>
          !(
            item.name === productName &&
            item.selectedSize === selectedSize &&
            item.selectedColor === selectedColor
          )
      )
    )
  }

  const clearCart = () => setCart([])

  const cartTotal = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  )

  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0)

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        updateQuantity, // Added this function
        removeFromCart,
        clearCart,
        cartTotal,
        cartItemCount,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
