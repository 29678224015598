import { Suspense, lazy } from 'react'
import ScrollToTop from './helpers/scroll-top'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// context
import { CategoriesProvider } from './context/CategoriesContext'
import { ProductProvider } from './context/ProductContext'
import { SingleProductProvider } from './context/SingleProductContext'
import { CartProvider } from './context/CartContext'
import { OrderProvider } from './context/OrderContext'

const queryClient = new QueryClient()

// home pages
const HomeMedicalEquipment = lazy(() =>
  import('./pages/home/HomeMedicalEquipment')
)

// shop pages
const ShopGridStandard = lazy(() => import('./pages/shop/ShopGridStandard'))

// product pages

const Product = lazy(() => import('./pages/shop-product/Product'))

// other pages
const About = lazy(() => import('./pages/other/About'))
const Contact = lazy(() => import('./pages/other/Contact'))

const Cart = lazy(() => import('./pages/other/Cart'))

const Checkout = lazy(() => import('./pages/other/Checkout'))

const NotFound = lazy(() => import('./pages/other/NotFound'))

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className='flone-preloader-wrapper'>
              <div className='-preloader'>
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <QueryClientProvider client={queryClient}>
            <CategoriesProvider>
              <ProductProvider>
                <SingleProductProvider>
                  <CartProvider>
                    <OrderProvider>
                      <Routes>
                        {/* Homepages */}
                        <Route path={'/'} element={<HomeMedicalEquipment />} />

                        {/* Shop pages */}
                        <Route path={'/shop'} element={<ShopGridStandard />} />

                        {/* Shop product pages */}

                        <Route path={'/product/:id'} element={<Product />} />

                        {/* Other pages */}
                        <Route path={'/about'} element={<About />} />
                        <Route path={'/contact'} element={<Contact />} />

                        <Route path={'/cart'} element={<Cart />} />

                        <Route path={'/checkout'} element={<Checkout />} />

                        <Route path='*' element={<NotFound />} />
                      </Routes>
                    </OrderProvider>
                  </CartProvider>
                </SingleProductProvider>
              </ProductProvider>
            </CategoriesProvider>
          </QueryClientProvider>
        </Suspense>
      </ScrollToTop>
    </Router>
  )
}

export default App
